#wrapper {
	#sidebar-wrapper {
        overflow-x: hidden;

        .sidebar-nav {
            overflow-x: hidden;
        }

		.sidebar-nav > ul > li.active > a,
		.sidebar-nav > ul > li:hover > a {
			background-image: url(/assets/img/icons/arrow_right_orange.png);
			background-repeat: no-repeat;
			background-position: right 10px center;
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				margin: 0;
				line-height: 50px;
				border-bottom: 1px solid #d4d4d4;

				a {
					display: block;
					height: 100%;
					color: #000;
					height: 50px;
					text-decoration: none;
					padding: 0px 20px;
					white-space: nowrap;
					overflow: hidden;
				}

				a span.icon {
					display: inline-block;
					width: 20px;
                    height: 16px;
                    margin-top: 17px;
					background-position: center center;
					background-repeat: no-repeat;
                    background-image: url(/assets/img/wika_sprite.png);
					float: left;

                    &.icon-none {
                        background: none;
                    }


					&.icon-dashboard {
                        background-position: 0px 0px;
					}

					&.icon-account {
                        background-position: 0px -17px;
					}

					&.icon-plans {
                        background-position: 0px -34px;
                        height: 14px;
					}

					&.icon-support {
                        background-position: -1px -61px;
					}

					&.icon-calculator {
                        background-position: 0px -47px;
					}

                    &.icon-logout {
                        background-position: -1px -79px;
                    }
				}
			}

            ul li {
                line-height: 35px;
                a {
                    height: 35px;
                }
            }

			li.active,
			li:hover {

				a span.icon {
					&.icon-dashboard {
                        background-position: -20px 0px;
					}

					&.icon-account {
                        background-position: -20px -17px;
					}

					&.icon-plans {
                        background-position: -20px -34px;
					}

					&.icon-support {
                        background-position: -25px -61px;
					}

					&.icon-calculator {
                        background-position: -20px -47px;
					}

                    &.icon-logout {
                        background-position: -20px -79px;
                    }
				}
			}

			li.active > ul > li {
				background-color: #d4d4d4;

				> a:hover, &.active > a {
					background-color: darken(#d4d4d4, 20%);
				}
			}

			ul {
				display: none;
				background-color: #d4d4d4;
			}

			li.active > ul {
				display: block;
			}

            > li.slide > a,
			> li.direct a {
                background-color: darken(#d4d4d4, 30%) !important;
            }

			> li.slide:hover > a,
			> li.slide > a:hover,
			> li.slide.active > a,
			> li.direct:hover > a,
			> li.direct > a:hover,
			> li.direct.active > a {
                background-color: darken(#d4d4d4, 40%) !important;
                color: #fff !important;
            };
		}

		> ul > li.active,
		> ul > li:hover {
			margin-right: -8px;
		}

		ul li:hover > a,
		ul li.active > a {
			color: #fff;
			background-color: @wika-orange;
		}

        ul li.slide:hover > a,
        ul li.slide.active > a {
            background-color: darken(#d4d4d4, 20%);
        }

		.sidebar-brand img {
			display: block;
			margin: 0px auto;
			max-width: 100%;
		}


	}
}
