#pyramid {
  .pyramid-container {
    text-align: center;
  }

  .inside-text {
    position: absolute;
    bottom: -40px;
  }

  .pyramid-level {
    margin-bottom: 5px;
    position: relative;
    line-height: 40px;

    .margin, .visible {
      height: 0;
      margin-left: auto;
      margin-right: auto;
    }

    .text {
      color: #fff;
      text-align: center;
      position: absolute;
      left: 0px;
      right: 0px;
      width: 100%;
      top: 0px;
      z-index: 2;

      a {
        color: #fff;
      }

      a:hover {
        text-decoration: none;
      }
    }
  }

  .pyramid-level .visible {
    border-left: 80px solid transparent;
    border-right: 80px solid transparent;
    border-bottom: 160px solid @wika-blue;
  }

  .pyramid-level:hover .visible {
    border-bottom-color: lighten(@wika-blue, 10%);
  }

  .pyramid-level.level-1 .visible { width: 160px; }
  .pyramid-level.level-2 .visible { width: 325px; }
  .pyramid-level.level-3 .visible { width: 490px; }

  @media only screen and (max-width: @screen-md-max) {
    @h: 60px;

    .pyramid-level {
      line-height: @h / 2;
      font-size: 0.9em;
    }

    .pyramid-level .visible {
      border-left: @h solid transparent;
      border-right: @h solid transparent;
      border-bottom: @h*2 solid @wika-blue;
    }
    .pyramid-level.level-1 .visible { width: @h*2; }
    .pyramid-level.level-2 .visible { width: @h*4+5px; }
    .pyramid-level.level-3 .visible { width: @h*6+10px; }
  }

  @media only screen and (max-width: @screen-sm-max) {
    @h: 50px;

    .pyramid-level {
      line-height: @h / 2;
      font-size: 0.9em;
    }

    .pyramid-level .visible {
      border-left: @h solid transparent;
      border-right: @h solid transparent;
      border-bottom: @h*2 solid @wika-blue;
    }
    .pyramid-level.level-1 .visible { width: @h*2; }
    .pyramid-level.level-2 .visible { width: @h*4+5px; }
    .pyramid-level.level-3 .visible { width: @h*6+10px; }
  }

  @media only screen and (max-width: @screen-sm-max) {
    @h: 50px;

    .pyramid-level {
      line-height: @h / 2;
      font-size: 0.8em;
    }

    .pyramid-level .visible {
      border-left: @h solid transparent;
      border-right: @h solid transparent;
      border-bottom: @h*2 solid @wika-blue;
    }
    .pyramid-level.level-1 .visible { width: @h*2; }
    .pyramid-level.level-2 .visible { width: @h*4+5px; }
    .pyramid-level.level-3 .visible { width: @h*6+10px; }
  }
}
