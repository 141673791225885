

@hover-color: lighten(@wika-blue, 10%);
@focus-color: @wika-blue;
@active-color: @focus-color;

/* @form header themes 
------------------------------------ */
.smart-forms .header {	
	background-color:@active-color;
	border-top:1px solid #7ABBE7;  
	border-bottom:5px solid #0087bb;
}

.smart-forms .header-lite{ border-top-color:#7ABBE7; }
.smart-forms .header-lite:before{ background-color:@active-color; }
.smart-forms .header h4{ color:#fff; }

/* @form tagline 
-------------------------------------- */
.smart-forms .tagline span{ color:@active-color; }
.smart-forms .smart-link{ color:@active-color; }

/* @hover | focus  
---------------------------------------- */
.smart-forms .gui-input:hover,
.smart-forms .gui-textarea:hover,
.smart-forms .select > select:hover,
.smart-forms .select-multiple select:hover, 
.smart-forms .gui-input:hover ~ .input-hint,
.smart-forms .file .gui-file:hover + .gui-input,
.smart-forms .gui-textarea:hover ~ .input-hint{ border-color: @hover-color; }

.smart-forms .gui-input:focus,
.smart-forms .gui-textarea:focus,
.smart-forms .select > select:focus,
.smart-forms .select-multiple select:focus{ 
	border-color: @active-color;
	-webkit-box-shadow:0px 0px 1px @active-color inset; 
	-moz-box-shadow:0px 0px 1px @active-color inset; 
	-o-box-shadow:0px 0px 1px @active-color inset; 	
	box-shadow:0px 0px 1px @active-color inset; 
	color: #3C3C3C; 
}

.smart-forms .gui-textarea:focus{  height: 120px; }
.smart-forms .select > select:focus { z-index:10; z-index:20\9; }
.smart-forms .gui-input:focus ~ .field-icon i,
.smart-forms .gui-textarea:focus ~ .field-icon i{ color:@active-color; }
.smart-forms .select-multiple select:focus, 
.smart-forms .gui-input:focus ~ .input-hint,
.smart-forms .gui-textarea:focus ~ .input-hint, 
.smart-forms .file .gui-file:focus + .gui-input{ border-color: @active-color; }
.smart-forms .select > select:focus + .arrow{ color:@active-color; }

/* @star rating 
------------------------------------------------- */
.smart-forms .rating:hover .rating-star:hover,
.smart-forms .rating:hover .rating-star:hover ~ .rating-star,
.smart-forms .rating-input:checked ~ .rating-star { color: @active-color;	}
.smart-forms .rating-star, 
.smart-forms .rating:hover .rating-star {	color: #A2A6A8;	 }


/* @checkbox + radio 
--------------------------------------------------- */
.smart-forms .option input:hover + .checkbox,  
.smart-forms .option input:hover + .radio{ border-color: @hover-color; }
.smart-forms .option input:checked + .checkbox, 
.smart-forms .option input:focus + .checkbox, 
.smart-forms .option input:checked + .radio, 
.smart-forms .option input:focus + .radio{ border-color: @active-color; }
.smart-forms .option input:checked + .radio:before, 
.smart-forms .option input:focus + .radio:before{  background: @active-color; }
.smart-forms .option input:checked + .checkbox:before, 
.smart-forms .option input:focus + .checkbox:before{ border-color: @active-color; }

/* @toggle-switch 
--------------------------------------------- */
.smart-forms .switch > input:checked + label { background: @active-color; border-color: @active-color; }
.smart-forms .switch > input:checked + label:after { color:@active-color; }
.smart-forms .switch > input:checked:focus + label { background: #0087bb; border-color: #0087bb; }

/* @button 
----------------------------------------------- */
.smart-forms .smart-btn { background-color: @active-color; }
.smart-forms .smart-btn:hover,
.smart-forms .smart-btn:focus{ background-color: @hover-color; }
.smart-forms .smart-btn:active{ background-color: #0087bb; }
.smart-forms .smart-btn, 
.smart-forms .smart-btn:hover,
.smart-forms .smart-btn:focus, 
.smart-forms .smart-btn:active{ color: #fff; text-shadow: 0 1px rgba(0, 0, 0, 0.08);  }

/* @datepicker overides 
-------------------------------------------------- */
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-title { color:@active-color; }
.ui-datepicker-today a, 
.ui-datepicker-today a:hover, 
.ui-datepicker .ui-state-highlight { background:@active-color!important; }

/*  @price-box 
------------------------------------------------------ */
.smart-forms .selected-box h4{ color:@active-color; }
.smart-forms .ribbon-inner{ background: @active-color; }
.smart-forms .ribbon-inner:before,
.smart-forms .ribbon-inner:after{ border-top-color:#0087bb; }

.gui-imput {
	font-family: Roboto, Arial, Helvetica, sans-serif !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-variant: normal !important;
	font-weight: normal !important;
}