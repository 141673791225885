@wika-blue: #0b2842;
@wika-orange: #e27832;
@wika-brown: #ae9a63;



@import "../../../node_modules/bootstrap-less/bootstrap/bootstrap";
@import 'smart-forms';
@import 'smart-forms-theme';
@import 'layout';
@import 'nav';
@import 'dashboard';
@import 'widgets';
@import 'login';
@import 'account';
@import 'pyramid';

@import url(http://fonts.googleapis.com/css?family=Raleway);

html {
    -webkit-text-size-adjust: 100%;
    height: 100%;
}

body {
    height: 100%;
	min-height: 100%;
	margin: 0;
	padding: 0;
	font-family: 'Open Sans', sans-serif;
	overflow-y: scroll;
	line-height: 150%;
	font-size: 13px;
	color: #202020;
    background-image: url(/assets/img/bg.png);
    background-position: top left;
    background-repeat: no-repeat;
}

input, textarea {
	-webkit-appearance: none;
	-moz-appearance: none;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-family: 'Open Sans', sans-serif;
	font-size: 13px;
	outline: 0 none;
}

table.table {
	th {
		color: @wika-blue;
	}
}

table {
	td {
		vertical-align: text-top;
	}
}

input[type="text"], input[type="password"], textarea {
	border: 1px solid #d4d4d4;
	padding: 5px 10px;
}

label.input {
	input.gui-input {
		height: 37px !important;
	}

	label {
		height: 39px !important;
		line-height: 39px !important;
	}
}


h1, h2, h3, h4, h5, h6 {
	color: @wika-blue;
    font-family: 'Raleway', sans-serif;
}

span.icon {
	background-position: center center;
	background-repeat: no-repeat;
	width: 16px;
	height: 16px;
	display: inline-block;
	vertical-align: text-bottom;

	&.icon-cross-blue {
		background-image: url(/assets/img/icons/cross_blue.png);
	}

	&.icon-arrow-right {
		background-image: url(/assets/img/icons/arrow_right_blue.png);
	}
}

.btn {
	padding: 4px 10px;
}

h5 {
	font-weight: 600;
	margin-bottom: 5px;
}


.radio, .option {
	margin-top: 0px !important;
	margin-bottom: 0px !important;
}

/*
.smart-forms .select, .smart-forms .gui-input, .smart-forms .select > select, .smart-forms .select-multiple select {
	height: 32px !important;
}

.smart-forms .gui-input, .smart-forms .gui-textarea {
	padding: 5px !important;
}

.smart-forms .select > select {
	padding: 5px 10px !important;
}

.smart-forms .select .arrow {
	top: 5px !important;
}
*/

label.input {
	display: block !important;
}

div.table-wrapper {
	overflow-x: auto;
}

.btn-wika {
	background-color: @wika-blue;
	color: #fff;


	&:hover {
		background-color: lighten(@wika-blue, 10%);
		color: #fff;
	}
}

.btn.btn-default {
	.btn-wika;
}

.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	background-color: @wika-blue !important;
}

.pagination>li>a, .pagination>li>span {
	color: @wika-blue;
}

.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus {
	color: @wika-blue;
}

.progress {
  position: relative;
}

.progress span {
  position: absolute;
  display: block;
  width: 100%;
  color: black;
  line-height: 30px;
}

iframe {
    width: 100%;
    height: 100%;
    min-height: 800px;
    background-color: #fff;
    border: none;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 1;
}

#iframe_loading {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    min-height: 800px;
    background-color: #fff;
    z-index: 2;

    img {
        display: block;
        margin: 50px auto;
    }
}

@media screen and (max-width: 768px) {
    iframe, #iframe_loading {
        top: 45px;
    }
}

strong {
    font-weight: 600;
}

#togglenav {
    color: #000;
    font-size: 16px;
}

#togglenav:hover {
    text-decoration: none;
} 
    
.legal {
	text-align: center;
	margin-top: 25px;
	margin-bottom: 25px;
}

.legal a {
	font-size: 12px;
	color: #777676
}