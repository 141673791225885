#wrapper {
    min-height: 100%;
    max-width: 1300px;
    margin: 0px auto;
    position: relative;
    background-color: #ffffff;

	#sidebar-wrapper {
		background-color: #f9f9f9;
        box-sizing: border-box;
        min-height: 100%;

        .sidebar-nav {
            box-sizing: border-box;
            border-left: 1px solid #d4d4d4;
            border-right: 1px solid #d4d4d4;
            height: 100%;
            min-height: 100%;
        }
	}

	#content-wrapper {
		position: absolute;
		width: 100%;
		height: 100%;
		margin-left: 250px;
	}
}


#wrapper {
	padding-left: 0;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
    min-height: 100%;
}

@media(max-width: 768px) {
	#wrapper.toggled {
		padding-left: 200px;
	}
}

#sidebar-wrapper {
	z-index: 1000;
	left: 250px;
	width: 0;
	margin-left: -250px;
	overflow-y: auto;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;

}

#page-content-wrapper {
    min-height: 100%;
    position: relative;
    background-color: #fff;
}

@media(max-width: 768px) {
	#wrapper.toggled #sidebar-wrapper {
		width: 200px;
	}

	#page-content-wrapper {
		width: 100%;
		//padding: 15px;
		padding-top: 15px;
	}

	#wrapper.toggled #page-content-wrapper {
		position: absolute;
		margin-right: -200px;
	}

    #sidebar-wrapper {
        position: absolute;
        top: 0px;
        bottom: 0px;
    }

;
}

/* Sidebar Styles */

.sidebar-nav {
	position: absolute;
	top: 0;
	width: 250px;
	margin: 0;
	padding: 0;
	list-style: none;
}

.sidebar-nav li {
	text-indent: 20px;
	line-height: 40px;
}

@media(max-width: 769px) {
	.navbar-salutation {
		display: none;
	}
}

@media(min-width:769px) {
	#wrapper {
		padding-left: 250px;
	}

	#sidebar-wrapper {
		width: 200px;
	}

	#page-content-wrapper {
		padding: 20px;
	}

	#togglenav {
		display: none;
	}
}

@media(max-width: 1024px) {
	#wrapper.toggled #sidebar-wrapper {
		width: 200px;
	}

	.sidebar-nav {
		width: 200px;

		ul li a {
			padding-left: 10px !important;
		}
	}
}

@media(max-width: 1024px) and (min-width: 769px)  {
	#wrapper {
		padding-left: 200px;
	}
}

@media(min-width: 1024px) {
	#sidebar-wrapper {
		width: 250px;
	}
}
