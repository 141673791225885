section.widget {
	margin-bottom: 35px;
	
	h3 {
		font-size: 20px;
		line-height: 38px;
		margin-top: 5px;
		border-bottom: 1px solid @wika-blue;
		color: @wika-blue;
		margin-bottom: 15px;
	}

	@media (max-width: @screen-sm) {
		h3 {
			font-size: 1.3em;
		}
	}

	h4 {
		color: @wika-blue;
		font-size: 14pt;
	}

	p {
		text-align: justify;
	}

    &.side {
        border: 1px solid #c6c6c6;
        border-radius: 2px;
        padding: 0 20px 10px;

        h3 {
            color: @wika-orange;
            border-color: @wika-orange;
        }
    }
}

section.widget.faq {
	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			border-top: 1px solid #d9d9d9;
			margin: 0;
			padding: 0;
		}

		li:first-child {
			border: none;
		}

		a {
			padding-left: 6px;
			padding-right: 28px;
			line-height: 30px;
			display: block;
			background-image: url(/assets/img/icons/arrow_right_blue.png);
			background-position: right 6px center;
			background-repeat: no-repeat;
			color: #333333;
		}

		li.active a {
			background-image: url(/assets/img/icons/arrow_down.png);
		}

		a:hover, li.active a {
			background-color: @wika-blue;
			color: #fff;
			text-decoration: none;
		}

		p {
			padding: 6px;
			display: none;
		}
	}
}

section.widget.contact {
	h4 {
		font-size: 16px;
		margin-bottom: 7px;
	}
}

section.widget.welcome {
}

section.widget.plans {
	div.plan {
		border-bottom: 1px solid #e5e5e5;
		padding: 15px 0px;

        &:last-child {
            border-bottom: none;
        }

		h4 {
            font-size: 110%;
            margin-bottom: 5px;
            font-weight: 600;
		}

        a.pull-right {
            margin-right: 15px;
        }
	}

    .panel-body .plan:first-child {
        padding-top: 0px;
    }

    table {
        tr:nth-child(odd) {
            td {
                background-color: #f1f1f1;
            }
        }
    }
}

section.widget.plans-list {
	div.plans-category {
		h4 {
			display: block;
			background-color: #f1f1f1;
			line-height: 30px;
			font-size: 13px;
			font-weight: bold;
			padding-left: 9px;
			margin-top: 2px;

            small {
                font-size: 85%;
            }
		}

		div.plan {
			padding: 0px 9px;
			border-bottom: 1px solid #f1f1f1;
			position: relative;
		
			h5 {
				font-size: 12px;
			}

			p {

			}

			button {
				position: absolute;
				right: 0px;
				bottom: 9px;

				.btn-default;
				background-color: #f1f1f1;
			}

			@media (max-width: @screen-xs-max) {
				button {
					position: static;
					margin-bottom: 9px;
				}
			}
		}
	}

	div.plans-category.inactive {
		h4 {
			color: #777777;
		}

		div.plan {
			h5 {
				color: #ababab;
			}
			
			p {
				color: #ababab;
			}

			button {
				.btn-wika;
			}
		}
	}
}

section.widget.support {
	input[type="text"], textarea {
		width: 100%;
	}
}

section.widget.news {
	article {
        border: 1px solid #d4d4d4;
        border-radius: 2px;
        padding: 15px;
        margin-bottom: 15px;

		.time {
			color: #999;
			text-align: right;
			font-size: 0.8em;
			margin-top: -10px;
            margin-bottom: 0px;
		}
	}
}

section.widget.editnews {
	input[type="text"], textarea {
		width: 100%;
	}
}

section.widget.account {
	.container-fluid {
		position: relative;
		padding: 0;

		.edit, .edit_confirm {
			display: block;
			width: 22px;
			height: 22px;
			position: absolute;
			bottom: 5px;
			right: 5px;
		}

		.edit {
			background-image:url(/assets/img/icons/icon_edit.png);
		}

		.edit:hover {
			background-image:url(/assets/img/icons/icon_edit_hover.png);
		}

		.edit_confirm {
			background-color: #fff !important;
			background-image:url(/assets/img/icons/icon_edit_confirm.png);
		}
	}

	table {
		tr {
			td {
				line-height: 30px;

				input {
					line-height: 16px;
				}
			}

			@media (min-width: @screen-sm-min) {
				td:first-child {
					min-width: 280px;
				}
			}

			@media (max-width: @screen-xs-max) {
				td:first-child {
					padding-right: 10px;
				}
			}

			td:nth-child(3) {
				padding-left: 10px;
				color: #f00;
			}
		}
	}
}


section.widget.admin.userdata {
	table {
		tr {
			td {
				line-height: 30px;

				input {
					line-height: 16px;
				}
			}

			td:first-child {
				min-width: 150px;
			}

			td:nth-child(3) {
				padding-left: 10px;
			} 
		}
	}
}

section.widget.financereport {
	div.pyramid {
		margin: 0px auto;
		max-width: 500px;
		height: 400px;
	}

    div.pyramid-level a {
        display: block;
        height: 100%;
        width: 100%;
    }

	@media (max-width: 430px) {
		div.pyramid {
			width: 100%;
			height: 160px;
		}

		div.pyramid > div {
			width: 100%;
		}
	}
}

section.widget.household-insurance {
	table {
		width: 100%;
		
		tr {
			td:last-child {
				text-align: right;
			}
		}
	}
}

section.widget {
	@progressbar-height: 30px;

	.progress-bar-wika {
		border-radius: 0;
		box-shadow: none;
		-webkit-box-shadow: none;
		height: @progressbar-height;

		.progress-bar {
			background-color: @wika-orange;
			box-shadow: none;
			-webkit-box-shadow: none;
			line-height: @progressbar-height;
			height: @progressbar-height;
		}
	}
}

.calculator-pane {
    margin-top: 20px;
    text-align: center;

    img {
        display: block;
        margin: 10px auto;
        height: 74px;
    }

    a {
        display: inline-block;
        margin: 10px auto;
        width: auto;
        min-width: 100px;
    }
}
