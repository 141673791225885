#login {
	margin: 0px auto;
	max-width: 300px;
	width: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -150px;
	margin-top: -120px;

	p a {
		line-height: 33px;
		color: #777676;
		font-size: 0.9em;
	}

	input {
		display: block;
		width: 100%;
	}

	input[type="text"], input[type="password"] {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}

	input[type="submit"] {
		width: 40%;
		min-width: 100px;
		float: right;
	}
}

@media screen and (max-width: 400px) {
	#login {
		position: static;
		margin: 100px auto 15px;
	}
}